<template>
    <div id="company">
        <b-row>
            <b-col sm="8">
                <div class="card">
                    <div slot="header" class="card-header">
                        <i class="icon-bubble"></i>
                        <span v-if="$route.params.id == '-1'">Unternehmen anlegen</span>
                        <span v-else>Unternehmen bearbeiten</span>
                    </div>
                    <div v-if="company" class="card-body">
                        <b-form v-on:submit.prevent="onSubmit" novalidate>
                            <b-tabs v-model="tabIndex">
                                <b-tab active>
                                    <template slot="title">
                                        <i class="fa fa-table"></i> Stammdaten <span v-if="tabErrors.tab1"
                                                                                     class="badge badge-danger"><i
                                            class="fa fa-exclamation"></i></span>
                                    </template>

                                    <b-form-group>
                                        <c-switch class="mx-4 switch-sm" v-model="company.enabled" color="primary" id="enabled" variant="3d" />
                                        <label for="enabled" class="switch--label">Aktiv</label>
                                    </b-form-group>

                                    <b-form-group>
                                        <label for="name">Name</label>
                                        <b-form-input type="text" id="name" v-model="company.name"
                                                      placeholder="Name"></b-form-input>
                                    </b-form-group>

                                    <b-form-group>
                                        <label for="slug">Slug</label>
                                        <b-form-input type="text" id="slug" v-model="company.slug" placeholder="Leer lassen um automatisch Slug zu generieren"></b-form-input>
                                    </b-form-group>
                                    <b-row>
                                        <b-col>
                                            <label>Branchen</label>
                                            <SelectProperty
                                                    no-label multiple
                                                    :horizontal="{ label: 'col-sm-12', input: 'col-sm-12'}"
                                                    v-model="company.branches"
                                                    :property="'branch'"
                                            />
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col class="mb-2 mt-3" md="12">
                                            <h4>Webseiten</h4>
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group>
                                                <label for="website">Hauptwebseite</label>
                                                <b-form-input type="text" id="website" v-model="company.website" placeholder="zB.: https://www.example.com"></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group>
                                                <label for="websiteTypo3">Typo3 Link</label>
                                                <b-form-input type="text" id="websiteTypo3" v-model="company.websiteTypo3" placeholder="zB.: https://www.example.com"></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <hr/>
                                    <b-row>
                                        <b-col class="mb-2 mt-3" md="12">
                                            <h4>Kontaktinformationen</h4>
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group>
                                                <label for="email">E-Mail</label>
                                                <b-form-input type="text" id="email" v-model="company.email" placeholder="zB.: example@domain.com"></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group>
                                                <label for="phone">Telefonnummer</label>
                                                <b-form-input type="text" id="phone" v-model="company.phone" placeholder="zB.: 0123 45678901"></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <hr/>

                                    <b-row class="mt-3">

                                        <b-col class="mb-2 mt-3" md="12">
                                            <h4>Social Media</h4>
                                        </b-col>

                                        <b-col sm="4">
                                            <b-form-group>
                                                <label for="icon_key">Icon</label>
                                                <b-form-select v-model="socialMedia.key" :options="socialMediaKeys" value="-1" ></b-form-select>
                                            </b-form-group>
                                        </b-col>

                                        <b-col sm="5">
                                            <b-form-group>
                                                <label for="label">Name</label>
                                                <b-form-input type="text" id="label" v-model="socialMedia.label"
                                                              placeholder="z.B. https://www.instagram.com/communiacs"></b-form-input>
                                            </b-form-group>
                                        </b-col>

                                        <b-col sm="3" class="pt-4">
                                            <b-button type="button" stacked size="sm" variant="outline" color="primary"
                                                      @click="company.socialMedia.push(socialMedia), socialMedia = {}" class="mr-1 btn-secondary"
                                                      :disabled="!(socialMedia.key && socialMedia.label)"
                                            ><i class="fa fa-plus-square"></i>
                                                Hinzufügen
                                            </b-button>&nbsp;
                                        </b-col>

                                        <b-col cols="12">
                                            <v-client-table v-if="company.socialMedia && company.socialMedia.length > 0" ref="table" :api-mode="false"
                                                            :data="company.socialMedia" :columns="['key', 'label', 'actions']" :options="socialMediaTableOptions">
                                                <template slot="actions" slot-scope="props">
                                                    <b-button v-if="props.index != company.socialMedia.length" size="sm" variant="secondary" class="px-4" @click="moveFromArray(props, 'socialMedia', 'down')"><i class="fa fa-long-arrow-down"></i></b-button>
                                                    <b-button v-if="props.index != 1" size="sm" class="px-4" @click="moveFromArray(props, 'socialMedia', 'up')"><i class="fa fa-long-arrow-up"></i></b-button>
                                                    <b-button size="sm" color="danger" variant="secondary" class="px-4" @click="removeFromArray(props, 'socialMedia')"><i class="fa fa-trash"></i></b-button>
                                                </template>
                                            </v-client-table>
                                        </b-col>

                                    </b-row>
                                    <b-row>
                                        <b-col class="mb-2 mt-3" md="12">
                                            <h4>Medien</h4>
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group>
                                                <label for="videoId">Vimeo Video ID</label>
                                                <b-form-input type="text" id="videoId" v-model="company.videoId" placeholder="zB.: 76979871"></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col sm="4">
                                            <label>Bild</label><br>
                                            <div class="margin-bottom-10">
                                                <img v-if="company.image && company.image.id > 0"
                                                     v-bind:src="$store.state.mediaServiceUrl + company.image.uid + '/200/200'"
                                                     class="image-placeholder-150">
                                            </div>
                                            <b-button size="sm" @click="openImageSelection({mediaType: 'image'})" variant="dark"><i
                                                    class="fa"></i>Bild auswählen/ändern
                                            </b-button>&nbsp;
                                            <a class="fa fa-trash fa-lg text-primary"
                                               v-if="company.image && company.image.id" @click="deleteMedia('image')"></a>
                                        </b-col>
                                        <b-col sm="4">
                                            <label>Logo</label><br>
                                            <div class="margin-bottom-10">
                                                <img v-if="company.imageLogo && company.imageLogo.id > 0"
                                                     v-bind:src="$store.state.mediaServiceUrl + company.imageLogo.uid + '/200/200'"
                                                     class="image-placeholder-150">
                                            </div>
                                            <b-button size="sm" @click="openImageSelection({mediaType: 'imageLogo'})" variant="dark"><i
                                                    class="fa"></i>Bild auswählen/ändern
                                            </b-button>&nbsp;
                                            <a class="fa fa-trash fa-lg text-primary"
                                               v-if="company.imageLogo && company.imageLogo.id" @click="deleteMedia('imageLogo')"></a>
                                        </b-col>
                                        <b-col sm="4">
                                            <label>Logo Weiß</label><br>
                                            <div class="margin-bottom-10">
                                                <img v-if="company.imageWhiteLogo && company.imageWhiteLogo.id > 0"
                                                     v-bind:src="$store.state.mediaServiceUrl + company.imageWhiteLogo.uid + '/200/200'"
                                                     class="image-placeholder-150">
                                            </div>
                                            <b-button size="sm" @click="openImageSelection({mediaType: 'imageWhiteLogo'})" variant="dark"><i
                                                    class="fa"></i>Bild auswählen/ändern
                                            </b-button>&nbsp;
                                            <a class="fa fa-trash fa-lg text-primary"
                                               v-if="company.imageWhiteLogo && company.imageWhiteLogo.id" @click="deleteMedia('imageWhiteLogo')"></a>
                                        </b-col>
                                    </b-row>
                                    <br>
                                </b-tab>
                                <b-tab>
                                    <template slot="title">
                                        <i class="fa fa-building-o"></i> Standorte <span v-if="tabErrors.tab2" class="badge badge-danger"><i
                                            class="fa fa-exclamation"></i></span>
                                    </template>

                                    <b-row v-for="(location, index) in company.standorts" :key="index" class="border border-solid-lightgrey" style="padding: 10px; margin: 5px">
                                        <b-col md="10">
                                            <b-form-group class="mt-3">
                                              <c-switch class="switch-sm mr-4" v-model="location.mainLocation" color="primary" id="mainLocation" variant="3d" />
                                              <label for="mainLocation" class="switch--label">Hauptzweigstelle</label>
                                            </b-form-group>
                                            <b-form-group>
                                                <label for="title">Name</label>
                                                <b-form-input type="text" id="name" v-model="location.name"
                                                              placeholder=""></b-form-input>
                                            </b-form-group>
                                            <b-form-group>
                                                <label for="address">Adresse</label>
                                                <b-form-input type="text" id="address" v-model="location.address"
                                                              placeholder="Adresse"></b-form-input>
                                            </b-form-group>
                                            <b-form-group>
                                                <label for="location_city">Stadt</label>
                                                <b-form-input type="text" id="location_city" v-model="location.city"
                                                              placeholder=""></b-form-input>
                                            </b-form-group>
                                            <b-row>
                                                <b-col md="6">
                                                    <b-form-group>
                                                        <label for="lat">Latitude</label>
                                                        <b-form-input type="text" id="lat" v-model="location.lat" placeholder="Latitude"></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col md="6">
                                                    <b-form-group>
                                                        <label for="lon">Longitude</label>
                                                        <b-form-input type="text" id="lon" v-model="location.lon" placeholder="Longitude"></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col md="6">
                                                    <b-form-group>
                                                        <label for="zip">Zip</label>
                                                        <b-form-input type="text" id="zip" v-model="location.zip" placeholder="zB. 94032"></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col md="6">
                                                    <b-form-group>
                                                        <label for="country">Land</label>
                                                        <b-form-input type="text" id="country" v-model="location.country" placeholder="zB. Deutschland"></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                        <b-col md="2">
                                            <b-button variant="outline-danger" block @click="removeStandorte(index)"><i class="fa fa-trash fa-lg"/></b-button>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="5">
                                            <b-button @click="addStandorte()" style="margin-top: 2px; vertical-align: bottom" size="sm"
                                                      variant="dark"><i class="fa fa-plus"></i> Standort hinzufügen
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-tab>
                            </b-tabs>
                            <div class="footer-btn" slot="footer" style="margin: 10px;">
                                <b-button type="submit" stacked size="sm" variant="primary"><i class="fa fa-save"></i>
                                    Speichern
                                </b-button>&nbsp;
                                <b-button size="sm" href="/#/company/companies/" variant="danger"><i class="fa fa-ban"></i>
                                    Abbrechen
                                </b-button>
                            </div>
                        </b-form>
                    </div>
                </div>
            </b-col>

            <MediaSelection
                    :show="showMediaSelection"
                    :type="mediaType"
                    :mime-types="'image/jpeg,image/png'"
                    @close="showMediaSelection = false"
                    @save="saveMedia($event)"
            />

            <MediaSelection
                    :show="showVideoSelection"
                    :type="mediaType"
                    :mime-types="['video/youtube', 'video/mp4']"
                    @close="showVideoSelection = false"
                    @save="saveVideo($event)"
                    :title="'Videoauswahl'"
            />
        </b-row>
    </div>
</template>

<script>

    import Vue from 'vue'
    import MediaSelection from '../Media/MediaSelection'
    import {mapState, mapGetters} from 'vuex'
    import VueNotifications from "vue-notifications";
    import {quillEditor} from 'vue-quill-editor'
    import { Switch as cSwitch } from '@coreui/vue'
    import vSelect from 'vue-select'
    import SelectProperty from '../Properties/SelectProperty'
    
    import {ClientTable} from 'vue-tables-2'
    Vue.use(ClientTable)

    // require styles fpr quillEditor
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'

    const axios = require('axios')

    export default {
        components: {
            MediaSelection,
            quillEditor,
            SelectProperty,
            cSwitch,
            vSelect
        },
        name: 'editCompany',
        data() {
            return {
                showMediaSelection: false,
                showVideoSelection: false,
                mediaType: '',
                tabIndex: 0,
                tabErrors: {},
                customToolbar: {
                    modules: {
                        toolbar: [
                            ['bold', 'italic', 'underline', 'strike'],
                            [{'header': 1}, {'header': 2}],
                            [{'list': 'ordered'}, {'list': 'bullet'}],
                            [{'size': ['small', false, 'large', 'huge']}],
                            [{'color': []}],
                            [{'align': []}],
                            ['link']
                        ]
                    }
                },
                idx: 0,
                socialMedia: {},
                socialMediaKeys: ['facebook', 'instagram', 'youtube', 'linkedin', 'xing', 'tiktok'],
                socialMediaTableOptions: {
                    headings: {
                        edit: '',
                        url: 'Webseite'
                    },
                    filterable: false,
                    texts: {
                        //count: 'Showing {from} to {to} of {count} records|{count} records|One record - Test',
                        count: '',
                        filterPlaceholder: 'Search query',
                    },
                    pagination: false,
                },
            }
        },
        computed: {
            ...mapState({
                propertyOptions: state => state.company.property.propertyOptions,
            }),
            ...mapGetters({
              company: 'company/getCompany',
                error: 'company/getError'
            }),

        },
        mounted() {
            if (this.$route.params.id != '-1') {
                this.$store.dispatch('company/loadCompany', this.$route.params.id)
            } else {
                this.$store.dispatch('company/initCompany')
            }
        },
        methods: {
            // onPropertySelect() {
            //     for (let i = 0; i < selected.length; i++) {
            //         this.teaserOptions.push(selected[i])
            //     }
            //
            //     // check if selectedTeaser contains elements which ar not selected options => updateSelection
            //     let currentSelection = this.$store.getters['poi/property/getTeaserSelection']
            //     let newSelection = new Array()
            //     for (let i = 0; i < currentSelection.length; i++) {
            //         for (let j = 0; j < this.teaserOptions.length; j++) {
            //             if (currentSelection[i].id == this.teaserOptions[j].id) {
            //                 newSelection.push(currentSelection[i]);
            //             }
            //         }
            //     }
            //     this.$store.dispatch('poi/property/updateTeaserSelection', newSelection)
            // },
            moveFromArray(props, array, direction) {
                let element = props.row
                const idx = props.index - 1
                this.company[array].splice(idx, 1)

                let newIndex = 0
                if (direction == 'up') {
                    newIndex = idx - 1
                } else {
                    newIndex = idx + 1;
                }
                this.company[array].splice(newIndex, 0, element)
            },
            removeFromArray(props, array) {
                this.company[array].splice(props.index -1, 1)
            },
            onSubmit() {
                this.$store.dispatch('company/saveCompany').then(
                    response => {
                        if (response.status == 200 || response.status == 201) {
                            this.notifySaveSuccess()
                            if(this.$route.params.id == "-1") {
                              this.$router.push('/company/editCompany/' + response.data.id)
                              this.$store.dispatch('company/loadCompany', this.$route.params.id)
                              this.company.id = response.data.id
                            }
                        }
                    }, error => {
                        this.notifySaveError()
                    }
                )
            },
            openImageSelection: function (payload) {
                this.showMediaSelection = true
                this.showVideoSelection = false
                this.mediaType = payload.mediaType
                this.mimeType = 'image/jpeg,image/png'
                this.idx = payload.idx

            },
            openVideoSelection: function (payload) {
                this.showMediaSelection = false
                this.showVideoSelection = true
                this.mediaType = payload.mediaType
                this.mimeType = 'video/*'
                this.idx = payload.idx
            },
            saveMedia: function (payload) {
                switch (this.mediaType) {
                    case 'image':
                        Vue.set(this.company, 'image', {
                            'id': payload.id,
                            'uid': payload.uid
                        })
                        break
                    case 'stelle':
                        Vue.set(this.company.stellenangebotes[this.idx], 'image', {
                            'id': payload.id,
                            'uid': payload.uid
                        })
                        Vue.set(this.company.stellens[this.idx], 'image', {
                            'id': payload.id,
                            'uid': payload.uid
                        })
                        break
                    case 'offer':
                        Vue.set(this.company.stellenangebotes[this.idx], 'image', {
                            'id': payload.id,
                            'uid': payload.uid
                        })
                        break
                    case 'image_bg':
                        Vue.set(this.company, 'image_bg', {
                            'id': payload.id,
                            'uid': payload.uid
                        })
                        break
                    case 'imageWhiteLogo':
                        Vue.set(this.company, 'imageWhiteLogo', {
                            'id': payload.id,
                            'uid': payload.uid
                        })
                        break
                    case 'imageLogo':
                        Vue.set(this.company, 'imageLogo', {
                            'id': payload.id,
                            'uid': payload.uid
                        })
                        break
                }
            },
            saveVideo: function (payload) {
                if(this.mediaType == 'image') {
                    this.company.video = {
                        'id': payload.id,
                        'uid': payload.uid,
                        'image': payload.image,
                        'title': payload.title,
                    }
                } else if (this.mediaType == 'stelle'){
                    this.company.stellens[this.idx].video = {
                        'id': payload.id,
                        'uid': payload.uid,
                        'image': payload.image,
                        'title': payload.title,
                    }
                }
                // this.componentKey += 1;
            },
            deleteMedia: function (mediaType) {
                this.company[mediaType] = ''
            },
            deleteVideo: function (mediaType) {
                this.company.video = ''
            },

            // STANDORTEN VERWALTUNG
            addStandorte() {
                if (!this.company.standorts) {
                    this.company.standorts = []
                }
                Vue.set(this.company.standorts, this.company.standorts.length, {
                  mainLocation: false
                })
                // this.$forceUpdate()
            },
            removeStandorte: function (idx) {

                for(let i = 0; i < this.company.stellenangebotes.length; i++){
                    if(this.company.standorts[idx].id == this.company.stellenangebotes[i].standort) {
                        this.notifyAngeboteRelated()
                        return false
                    }
                }

                this.company.standorts.splice(idx, 1)
            },

            // STELLEN VERWALTUNG
            addStelle() {
                if (!this.company.stellens) {
                    this.company.stellens = []
                }
                let stelle = {}
                Vue.set(this.company.stellens, this.company.stellens.length, stelle)
                // this.$forceUpdate()
            },
            removeStellen: function (payload) {

                for(let i = 0; i < this.company.stellenangebotes.length; i++){
                    if(this.company.stellens[payload.index].id == this.company.stellenangebotes[i].stelle) {
                        this.notifyAngeboteRelated()
                        return false
                    }
                }

                this.company.stellens.splice(payload.index, 1)
            },

            // This Offers administration was moved to its own Component. This code can be safely deleted
            // // ANGEBOTE VERWALTUNG
            // addAngebote() {
            //     if (!this.company.stellenangebotes) {
            //         this.company.stellenangebotes = []
            //     }
            //     let angebote = {}
            //     Vue.set(this.company.stellenangebotes, this.company.stellenangebotes.length, angebote)
            //     // this.$forceUpdate()
            // },
            // removeAngebote: function (payload) {
            //     this.company.stellenangebotes.splice(payload.index, 1)
            //     // this.$forceUpdate()
            // },

        },

        notifications: {
            notifySaveSuccess: {
                type: VueNotifications.types.success,
                title: 'Firma wurde erfolgreich gespeichert',
            },
            notifySaveError: {
                type: VueNotifications.types.error,
                title: 'Fehler beim speichern der Firma'
            },
            notifyAngeboteRelated: {
                type: VueNotifications.types.error,
                title: 'It is not possible to remove this Stelle because it is related to a certain Stellenangebote'
            }
        }
    }
</script>
