<template>
    <div role="group" class="form-group form-row">
        <label :class="'col-form-label ' + horizontal.label" v-if="!noLabel"> Branche</label>
        <div :class="horizontal.input">
            <v-select
                    :clearable="multiple"
                    :multiple="multiple"
                    :closeOnSelect="closeOnSelect"
                    :options="allOptions()"
                    :reduce="e => e.value"
                    :value="value"
                    @input="$emit('input', $event)"
            ></v-select>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import vSelect from 'vue-select'

    export default {
        name: "SelectProperty",
        components: {vSelect},
        props: {
            property: {
                required: true,
                type: String
            },
            horizontal: {
                required: true
            },
            value: {
            },
            noLabel: {
                required: false,
                type: Boolean,
                default: false
            },
            closeOnSelect: {
                required: false,
                type: Boolean,
                default: false
            },
            multiple: {
                required: false,
                type: Boolean,
                default: false
            },
            loadOptions: {
                required: false,
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                allOptions() {
                    let opt = []
                    if(this.propertyStore[this.property]) {
                        this.propertyStore[this.property].forEach(e => {
                            opt.push({value: e.id, label: e.label})
                        })
                    }
                    return opt
                }
            }
        },
        created() {
            if(this.loadOptions) {
                this.$store.dispatch('company/property/loadProperties')
            }
        },
        computed: {
            ...mapState({
                propertyStore: state => state.company.property.propertyOptions
            }),
        }
    }
</script>

<style scoped>

</style>
